:root {
	/* text styles */
	--text-font: "Quicksand", sans-serif;

	--text-size-main-title: 70px;
	--text-size-title: 32px;
	--text-size-subtitle: 24px;
	--text-size-body: 18px;

	@media only screen and (max-width: 800px) {
		--text-size-main-title: 45px;
		--text-size-title: 26px;
		--text-size-subtitle: 20px;
		--text-size-body: 16px;
	}

	@media only screen and (max-width: 400px) {
		--text-size-main-title: 35px;
	}

	--text-weight-regular: 400;
	--text-weight-bold: 700;

	/* colors */
	--color-black: #404040;
	--color-dark-green: #2d574b;
	--color-light-green: #87a79d;
	--color-beige: #f9f7f3;
	--color-white: #fff;

	/* space */
	--space-xs: 8px;
	--space-s: 16px;
	--space-m: 24px;
	--space-l: 32px;
	--space-xl: 40px;

	/* border radius */
	--border-radius-small: 8px;
	--border-radius-large: 16px;
}
