/* CONTACT BALK*/
#contact {
    background: #87A79D;
    color: #fff!important;
    text-align: center;
}

.contact-form-wrapper {
    margin-top: 50px;
}

.contact-wrapper {
    display: flex;
    justify-content: space-around;
    margin: 50px 0;
}

.contact-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 25px;
    cursor: pointer;
    text-decoration: none;
}

.contact-item:hover {
    transform: scale(1.1);
}


.contact-item img {
    filter: brightness(0) invert(1);
    width: 15vw;
    height: auto;
    max-width: 150px;
    min-width: 90px;
}

a.contact-item {
    color: #fff!important;
    text-decoration: none;
}



@media only screen and (max-width: 800px) {
    .contact-wrapper {
        flex-wrap: wrap;
        word-break: break-word;
        margin: 0;
      }
    
      .contact-item {
        margin: 25px 15px;
      }
    
      .contact-icon img {
        width: 50px;
      }
    
      .contact-item p {
        text-decoration: underline;
        font-size: 16px;
        display: none;
      }
}

/* FOOTER */
footer {
    background-color:  #2D574B;
    height: 60px;
    width: 100vw;
    max-width: 1800px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 0 5vw;
}

.hannet {
    color: white;
    text-align: right;

    p {
        font-size: 14px;
    }
}
