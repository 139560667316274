.section {
	width: 100vw;
	height: fit-content;
	position: relative;
	padding: 75px 50px;
	padding-top: calc(var(--extra-padding) + 100px);
	max-width: 1800px;
	margin: auto;
}

@media only screen and (max-width: 800px) {
	.section {
		padding: 30px 20px;
		padding-top: calc(var(--extra-padding) + 60px);
	}
}
