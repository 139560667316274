.contentWrapper {
	position: relative;
	display: flex;
	flex-direction: row;
	max-width: 1200px;
	margin: auto;

	&.center {
		text-align: center;
	}

	&.center .textContentWrapper {
		justify-content: center;
	}

	&.left .textContentWrapper {
		justify-content: flex-start;
	}

	&.left .textContent {
		padding: 2em 2rem 2rem 0;
	}
	&.left .imageContent {
		right: 0;
		left: var(--element-width);
	}

	&.right .textContentWrapper {
		justify-content: flex-end;
	}

	&.right .textContent {
		padding: 2em 0 2rem 2rem;
	}
	&.right .imageContent {
		right: var(--element-width);
		left: 0;
	}

	@media only screen and (max-width: 800px) {
		.textContent {
			padding: 2em 0!important;
		}
	}
}

.textContentWrapper {
	display: flex;
}

.textContent {
	width: var(--element-width);
}

.imageContent {
	position: absolute;
	top: 0;
	height: 100%;
}

.imageContent > div {
	height: 100%;
	width: 100%;
}

.imageContent img {
	border-radius: 8px;
}

@media only screen and (max-width: 800px) {
	.contentWrapper {
		flex-direction: column-reverse;

		.imageContent {
			position: relative;
		}

		&.left .imageContent {
			left: initial;
		}

		img {
			max-height: 200px;
			object-position: center !important;
		}
	}

	.textContent {
		width: 100%;
	}
}
