/* header */
#menu {
    background-color:  #fff;
    z-index: 99;
    padding: 0 5vw;
    box-shadow: -1px 1px 7px 1px rgb(0 0 0 / 40%);
    position: fixed;
    width: 100vw;
    max-width: 1800px;
    margin: auto;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    .topnav {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 80px;
        width: 100%;
        max-width: 1920px;
        margin: auto;

        &.fixed {
            position: fixed;
        }

        #home {
            margin-right: auto;
            background-image: url('../../public/logo.png');
            background-size: contain;
            height: 70px;
            width: 47px;
            opacity: 1;
            
            @media screen and (max-width: 1000px) {
                height: 40px;
                width: 27px;
            }
        }

        .navlinks {
            display: flex;
            justify-content: space-around;
            align-items: center;
            list-style: none;
            width: 50%;
            min-width: 750px;
            height: 100%;
            cursor: pointer;

            a {
                color: var(--color-black);
                text-decoration: none;
                font-size: 18px; 
                font-weight: bold;
                font-family: 'Quicksand', sans-serif;

                &:hover {
                    color: #9C7938; 
                }
            }

            @media screen and (max-width: 1000px) {
                display: none;
            }
        }

        @media screen and (max-width: 1000px) {
            height: 60px;
        }
    } 
}

.hamburger-menu {
    display: none;
}


@media screen and (max-width: 1000px) {
    .hamburger-menu {
        display: block;

        &.is-active {

            .mobilemenu-items {
                transform: none;
            }
    
            span {
                opacity: 1;
                transform: rotate(45deg) translate(-2px, -1px);
                background: var(--color-black);
            }
        
            span:nth-last-child(3) {
                opacity: 0;
                transform: rotate(0deg) scale(0.2, 0.2);
            }
        
            span:nth-last-child(2) {
                transform: rotate(-45deg) translate(0, -1px);
            }
        }
    
        #menuToggle {
            display: block;
            position: fixed;
            top: 19px;
            right: 19px;
            width: fit-content;
            z-index: 99;
            margin: 0;
            user-select: none;
            font-family: 'Quicksand', sans-serif;;
            font-weight: 600;
            margin-bottom: 20px;

            .mobilemenu-icon {
                display: block;
                width: 40px;
                height: 32px;
                position: absolute;
                top: -7px;
                left: -5px;
                cursor: pointer;
                opacity: 0; /* hide this */
                z-index: 2; /* and place it over the hamburger */
                -webkit-touch-callout: none;
            }

            span {
                display: block;
                width: 33px;
                height: 4px;
                margin-bottom: 5px;
                position: relative;
                background: var(--color-black);
                border-radius: 3px;
                z-index: 1;
                transform-origin: 4px 0px;
                transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;

                &:first-child {
                    transform-origin: 0% 0%;
                }

                &:nth-last-child(2) {
                    transform-origin: 0% 100%;
                }
            }
        }   

        .mobilemenu-items {
            position: fixed;
            width: 100vw;
            left: 0;
            top: 60px;
            height: calc(100vh - 60px);
            padding: 30px;
            background: var(--color-beige);
            list-style-type: none;
            -webkit-font-smoothing: antialiased;
            transform-origin: 0% 0%;
            transform: translate(-100%, 0);
            transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

            .button-primary {
                color: #fff!important;
                font-weight: bold;
            }

            li {
                padding: 10px 0;
                font-size: 22px;
            }

            a:not([data-appearance="primary"]) {  
                text-decoration: none;
                color: #232323;
                transition: color 0.3s ease;
                font-weight: normal;
            }

            a[data-appearance="primary"] {  
                margin-top: 16px;
            }

            a:hover {
                color: #2D574B;
            }
        }
    }
  
}