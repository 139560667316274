.card {
	flex: 1;
	padding: 8px;
	position: relative;
	cursor: pointer;
}

/* TODO dit werkt nog niet */
.card a {
	position: relative;
}

.cardImage {
	height: 430px;
	width: 100%;
	border-radius: 8px;
	overflow: hidden;

	@media only screen and (max-width: 800px) {
		height: 230px;
	}
}

.card img {
	transition: 0.5s all;
}

.card:hover img {
	transform: scale(1.1);
}

.card h3 {
	position: absolute;
	bottom: 24px;
	left: 0;
	color: var(--color-white);
	width: 100%;
	text-align: center;
}
