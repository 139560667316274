@import url("https://fonts.googleapis.com/css?family=Quicksand");
@import url(./nav.scss);
@import url(./contact.scss);
@import url(./modals.scss);


* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	position: relative;
	font-family: var(--text-font);
	scroll-behavior: smooth;
}

h2 {
	font-size: var(--text-size-title);
}

h3 {
	font-size: var(--text-size-subtitle);
	margin-bottom: 0.5rem;
}

h4 {
	color: var(--color-dark-green);
}

p {
	font-size: var(--text-size-body);
}

.no-padding-top{
	padding-top: 0!important;
}

.padding-top{
	padding-top: 0!important;
}

.bottom-right {
	object-position: right bottom!important;

	@media only screen and (max-width: 800px) {
		opacity: 0.2;
	}
}