.modal {
    &.is-active {
        .modal-content-wrapper,
        .modal-bg {
            display: block;
        } 
    }
}

.modal-bg {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(0,0,0,0.5);
    bottom: 0;
    left: 0;
    z-index: 999;
    overflow: scroll;
    display: none;
    cursor: pointer;
}

.modal-content-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 50px;
    border-radius: 8px;
    z-index: 9999;
    width: 750px;
    display: none;

    .modal-exit {
        position: absolute;
        right: 25px;
        top: 25px;
        font-size: 1em;
        cursor: pointer;
        z-index: 99;
        font-weight: bold;

        &:hover {
            color: var(--color-dark-green);
            text-decoration: underline;
        }
    }

    .modal-title-wrapper {
        display: flex;
        align-items: center;

        .modal-title {
            margin-left: 15px;
        }

        .modal-icon {
            img {
                width: 50px;
            }
        }
    }

    .behandelingen-wrapper {
        background: #fff;
        z-index: 10;
        margin: 25px 0;

        .behandeling-row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            
            .behandeling-info-wrapper {
                flex: 9;
            }
    
            .behandeling-price {
                flex: 1;
                text-align: center;
                min-width: fit-content;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .modal-content-wrapper {
        padding: 50px 25px;
        width: 90vw;
        max-width: 90vw;

        .behandelingen-wrapper {
            min-width: initial;
            text-align: left;
        }

        .modal-exit {
            right: 25px;
            top: 25px;

            span {
                display: none;
            }
        }

        .modal-icon {
            display: none;
        }

        .modal-title-wrapper {
            .modal-title {
                margin-left: 0;
            }
        }
    }
}