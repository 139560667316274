.InBetweenWrapper {
	width: 100vw;
	height: fit-content;
	position: relative;
	z-index: 98;
	height: 30px;
}

.InBetween {
	background-color: var(--color-light-green);
	color: var(--color-white);
	padding: 24px;
	border-radius: var(--border-radius-large);
	width: 50%;
	margin: auto;
	position: relative;
	z-index: 11;
	display: flex;
	align-items: center;
	transform: translateX(-75px);
}

.InBetween h2 {
	color: var(--color-white);
	font-weight: var(--text-weight-regular);
	font-size: var(--text-size-subtitle);
	text-align: left;
	margin-bottom: var(--space-s) !important;
}

.textWrapper {
	color: var(--color-white);
	padding-right: 150px;
}

.InBetween img {
	position: absolute;
	right: -150px;
	max-width: 300px;
}

@media only screen and (max-width: 1240px) { 
	.InBetween {
		width:66%;
	}
}

@media only screen and (max-width: 800px) {
	.InBetweenWrapper {
		padding: 0 20px;
		width: 100%;
	}

	.InBetween {
		width: 100%;
		transform: initial;
	}

	.InBetween img {
		display: none;
	}

	.InBetween .textWrapper {
		padding-right: 0;
	}
}
