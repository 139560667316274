.imageContainer {
	display: inline-block;
	position: relative;
	line-height: 0;
	background: var(--color-white) fff;
	overflow: hidden;

	&::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		mix-blend-mode: lighten;
		background: #aed9cc;
		opacity: 0.1;
	}

	&.fullWidth {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		right: 0;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}

	&.flip {

		img {
			-webkit-transform: scaleX(-1);
			transform: scaleX(-1);
		}
	}
}
