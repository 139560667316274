.divider {
	position: absolute;
	bottom: -1px;
	left: 0;
	width: 100%;
	overflow: hidden;
	line-height: 0;
	z-index: 10;

	&.top {
		bottom: initial;
		top: -1px;
		transform: rotate(180deg);
	}

	svg {
		fill: var(--color-white);
	}
}
